<!--物业黑榜  -->
<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      @reset="reset"
      @add="add"
      @pop-confirm="popConfirm"
      @searchList="searchList"
      :dynamicSlotNames="['satisfaction', 'core']"
      @action-column-click="actionColumnClick"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="satisfaction" style="display: flex; align-items: center">
        <a-input-number
          placeholder="请输入"
          :default-value="100"
          :min="0"
          :max="100"
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          v-model="leftSatisfactionInterval"
        />-<a-input-number
          placeholder="请输入"
          :default-value="100"
          :min="0"
          :max="100"
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          v-model="rightSatisfactionInterval"
        />
      </div>
      <div slot="core" style="display: flex; align-items: center">
        <a-input-number
          placeholder="请输入"
          :default-value="10"
          :min="0"
          :max="10"
          v-model="leftScoreInterval"
        />-<a-input-number
          placeholder="请输入"
          :default-value="10"
          :min="0"
          :max="10"
          v-model="rightScoreInterval"
        />
      </div>
      <template slot="type" slot-scope="{ record }">
        <a-switch
          v-model="record.type"
          @change="updateEnabled(record, $event)"
        />
      </template>
    </ax-table>
    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="选择物业"
      :visible.sync="dialogAdd"
      width="50%"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addPage @closeDialog="dialogAdd = false" @refreshTable="refreshTable" />
    </el-dialog>
  </div>
</template>
<script>
import api from "./api";
import axTable from "@/components/ax-table";
import addPage from "./addPage.vue";

const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "propertyName",
    options: { placeholder: "请输入物业名称" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "物业负责人",
    type: "inputSearch",
    model: "head",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },

  {
    label: "居名满意度",
    type: "",
    model: "satisfaction",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "居民评分",
    type: "inputNumber",
    model: "core",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addPage, axTable },
  data() {
    //这里存放数据
    return {
      api,
      leftSatisfactionInterval: 0,
      rightSatisfactionInterval: 0,
      leftScoreInterval: 0,
      rightScoreInterval: 0,
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridId: "",
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchList", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [{ name: "add", text: "新增" }],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "物业名称",
            dataIndex: "propertyName",
            ellipsis: true,
            width: 150,
          },
          {
            title: "物业负责人",
            dataIndex: "head",
            ellipsis: true,
            width: 120,
          },
          {
            title: "物业联系电话",
            dataIndex: "phone",
            ellipsis: true,
            width: 120,
          },
          {
            title: "居民满意度",
            dataIndex: "satisfaction",
            ellipsis: true,
            width: 100,
          },
          {
            title: "居民评分",
            dataIndex: "core",
            ellipsis: true,
            width: 100,
          },
          {
            title: "是否展示",
            dataIndex: "type",
            maxWidth: 80,
            scopedSlots: { customRender: "type" },
          },
        ],
        true,
        {
          width: 120,
          actions: [
            {
              props: (record) => {
                return {
                  text: "移出",
                  name: "delete",
                  link: true,
                  type: "#FA5959",
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: { state: 1 },
      visible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    leftSatisfactionInterval(newVal) {
      if (newVal >= this.rightSatisfactionInterval) {
        this.rightSatisfactionInterval = newVal + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    rightSatisfactionInterval(newVal) {
      if (newVal <= this.leftSatisfactionInterval) {
        this.rightSatisfactionInterval = this.leftSatisfactionInterval + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    leftScoreInterval(newVal) {
      if (newVal >= this.rightScoreInterval) {
        this.rightScoreInterval = newVal + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    rightScoreInterval(newVal) {
      if (newVal <= this.leftScoreInterval) {
        this.rightScoreInterval = this.leftScoreInterval + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
  },
  //方法集合
  methods: {
    searchList(e) {
      const transformObject = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value[value.length - 1] : value;
          return acc;
        }, {});
      };
      e.formValues = transformObject(e.formValues);
      e.formValues.leftSatisfactionInterval =
        this.leftSatisfactionInterval || undefined;
      e.formValues.rightSatisfactionInterval =
        this.rightSatisfactionInterval || undefined;
      e.formValues.leftScoreInterval = this.leftScoreInterval || undefined;
      e.formValues.rightScoreInterval = this.rightScoreInterval || undefined;
      this.$refs.roleTable.getDataSource(e.formValues);
    },
    reset(e) {
      this.leftSatisfactionInterval = undefined;
      this.rightSatisfactionInterval = undefined;
      this.leftScoreInterval = undefined;
      this.rightScoreInterval = undefined;
    },
    async updateEnabled(record, value) {
      try {
        // 更新记录的启用状态
        record.type = value;

        // 打印更新后的值用于调试
        console.log(`更新后的启用状态: ${record.type}`);
        console.log(`记录类型: ${record.type}`);

        // 准备要发送给API的数据
        const value2 = {
          id: record.id || "",
          propertyName: record.propertyName || "",
          head: record.head || "",
          phone: record.phone || "",
          type: record.type, // 这里根据启用状态设置的类型值
          state: record.state || "",
        };

        // 发送API请求以更新记录
        const res2 = await api.update(value2);
        console.log(res2);

        // 向用户提供反馈
        this.$message.success("启用状态更新成功");
      } catch (error) {
        // 处理更新过程中出现的错误
        this.$message.error("启用状态更新失败");
        console.error(error.message);
      }
    },
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //移出
        case "delete":
          break;
      }
    },

    // 点击移出操作的api
    async popConfirm({ record }) {
      try {
        const res = await api.getById(record.id);
        // 假设 res.data 是一个对象而不是数组
        const row = res.data;
        // 提取并转换对象
        const value = {
          id: row.id || "",
          propertyName: row.propertyName || "",
          head: row.head || "",
          phone: row.phone || "",
          type: row.type || "",
          state: "",
        };
        const res2 = await api.update(value);

        // 如果所有请求都成功
        this.$message.success("移出成功");
        this.saveType = false;
        this.refreshTable();
      } catch (err) {
        this.$message.error("移出失败");
        this.saveType = false;
      }
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>