<!-- 选择物业 -->
<template>
  <div>
    <ax-table
      ref="roleTable3"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :sqlParams="sqlParams"
      :clickable="false"
      :rowSelection="{
        onChange: onSelectChange,
        type: radio,
      }"
    >
    </ax-table>
    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </div>
</template>

<script>
import api from "./api";
const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "propertyName",
    options: { placeholder: "输入关键字进行搜索", showTime: true },
    col: { span: 16 },
  },
];
export default {
  data() {
    return {
      api,
      radio: "radio", //单选
      selectionRows: {},
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      columns: this.$ax.tools.initColumn([
        {
          title: "物业名称",
          dataIndex: "propertyName",
          ellipsis: true,
          width: 150,
        },
        {
          title: "物业负责人",
          dataIndex: "head",
          ellipsis: true,
          width: 150,
        },
        {
          title: "物业联系电话",
          dataIndex: "phone",
          ellipsis: true,
          width: 300,
        },
      ]),
      dataSourceParams: {},
      sqlParams: {},
      rowSelection: {},
    };
  },
  watch: {
    rowSelection(e) {},
  },
  created() {},
  methods: {
    async onSubmit() {
      try {
        // 遍历所有选中的行，逐个提交
        for (const row of this.selectionRows) {
          const value = {
            id: row.id || "",
            propertyName: row.propertyName || "",
            head: row.head || "",
            phone: row.phone || "",
            type: row.type || "",
            state: "1",
          };

          const res = await api.update(value);
          console.log(res);

          if (res.status !== 200) {
            this.$message.error("新增失败");
            this.saveType = true;
            return; // 如果其中一个失败，停止处理并显示错误
          }
        }
        // 如果所有请求都成功
        this.$message.success("新增成功");
        this.saveType = false;
        this.$emit("closeDialog");
        this.$emit("refreshTable");
      } catch (err) {
        this.$message.error("新增失败");
        this.saveType = false;
      }
    },

    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //多选上传对象
    onSelectChange(selectedRowKeys, selectionRows) {
      this.$emit("selection-change", selectionRows);

      // 提取并转换对象
      const formattedSelection = selectionRows.map((row) => {
        return {
          id: row.id || "",
          propertyName: row.propertyName || "",
          head: row.head || "",
          phone: row.phone || "",
          type: row.type || "",
          state: "1",
        };
      });
      this.selectionRows = formattedSelection;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>